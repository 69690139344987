<template>
  <div>
    <div class="container container-global">
      <div class="wrap-form">
          <div class="form-forgot main-overlay-np">
              <div class="title-form">
                  <h1>Forgot Password?</h1>
                  <p>Type the address linked to your account and we'll send you password reset instructions.</p>
              </div>
              <AlertBox v-if="message.text" :message="message.text" :variant="message.type"/>   
              <form method="post" @submit.prevent="submitForm">
                  <div class="form-group form-group-cs mb-3">                   
                    <input type="text" class="form-control form-cs" v-model="email" placeholder="Enter your username or email" required />
                  </div>
                    
                  <div class="text-center mt-4">
                    <button type="submit" class="btn btn-orange" style="width: 180px;">Submit</button>
                  </div>
                  <div v-if="load" class="overlay">
                    <div class="loadding_circle"></div>
                  </div>
              </form>

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router';
import AlertBox from "../../components/Layout/AlertBox.vue";
import { isLogin, homePage } from "../../utils";

export default {
    name: "Login",
    components: {
        AlertBox,
    },
    setup(){
        const router = useRouter()
        const route = useRoute()
        if (isLogin()) {       
            router.push(homePage()); 
        }
        const store = useStore()
        const email = ref("")
        const load = ref(false)
        const message = reactive({
                text : "",
                type : "success"
        })

        
        const submitForm = () => {
            load.value = true
            message.text = ""
            store.dispatch("auth/forgot_password", {email:email.value} ).then(
                (res) => {
                    console.log(res)
                    message.text = res.message;
                    message.type = "success"
                    email.value = ""
                    load.value = false
                },
                (error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        message.text = error.response.data.message;
                    }else{
                        message.text = error
                    }
                    message.type = "danger"
                    load.value = false                          
                }
            );
        }

        if(route.query.email){
            email.value = route.query.email
            submitForm()
        }
        return { email, submitForm, load, message }
    } 
    
  
     
};
</script>

<style scoped>
.form-forgot {
    max-width: 480px;
    margin: auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 0 11px 0 rgb(0 0 0 / 10%);    
}
</style>