<template>
    <div id="AlertBox">
      <div :class="'alert alert-'+variant+' d-flex align-items-center'" role="alert'">
        <div v-html="message"></div>
      </div> 
    </div>
</template>
<script>
export default {
    name: "AlertBox",
    props: [
        "message",
        "variant"
    ]
}
</script>

<style>
</style>